<template>
  <div>
    <Navigation></Navigation>
    <megamenu @onFilter="handleChildFunctionCalled"></megamenu>
    <div class="container">
      <div class="col-12">
        <div class="card">
          <h3 style="text-align: center"><b>Privacy Policy</b></h3>
          <p>
            This Privacy Policy outlines the data practices for
            <a href="https://pcparthunt.com">https://pcparthunt.com</a>. As we
            do not collect any personal information from users, this policy
            explains how we handle non-personal data.
          </p>
          <ol>
            <li>
              Information We Do Not Collect We do not collect any personal
              information from users, such as names, email addresses, or contact
              details. We do not require users to create accounts or provide any
              personal information to access our website.
            </li>
            <li>
              Data Collection While you browse our website, we may collect
              non-personal information such as your IP address, browser type,
              and operating system. This information is collected automatically
              through cookies and similar technologies to enhance your browsing
              experience and improve our services.
            </li>
            <li>
              Use of Cookies We use cookies and similar technologies to analyze
              user behavior, track website traffic, and improve our services.
              You have the option to disable cookies through your browser
              settings, although this may affect the functionality of our
              website.
            </li>
            <li>
              Third-Party Links Our website may contain links to third-party
              websites, products, and services. We are not responsible for the
              privacy practices or content of these third parties. We encourage
              you to review the privacy policies of any third-party websites you
              visit.
            </li>
            <li>
              Changes to this Privacy Policy We reserve the right to update or
              modify this Privacy Policy at any time. Any changes will be
              reflected on this page, and we encourage you to review this
              Privacy Policy periodically for updates.
            </li>
            <li>
              Contact Us If you have any questions or concerns regarding this
              Privacy Policy, please contact us at
              <a href="pcparthunt2023@gmail.com">pcparthunt2023@gmail.com</a> .
            </li>
          </ol>
        </div>
      </div>
    </div>
    <pphfooter></pphfooter>
  </div>
</template>
  <script>
import Navigation from "../components/pphmenu.vue";
import megamenu from "../components/pphmegamenu.vue";
import pphfooter from "../components/pph_footer.vue";
export default {
  components: {
    megamenu,
    Navigation,
    pphfooter,
  },
  name: "PPHpp",
  data() {
    return {
      filters:{}
    };
  },
  mounted() {},
  methods: {
    async handleChildFunctionCalled(data) 
    {
      await this.GoToListWithFilter(data.brand, data.product_type, data.type, data.search);
    },
    async GoToListWithFilter(brand="", product_type = "", type = "", search = "") 
    {
     
      if (brand) {
        if (this.filters.brand) {
          this.filters.brand.push(brand);
        } else {
          this.filters.brand = [];
          this.filters.brand.push(brand);
        }
      }
      if (type) 
      {

        if (this.filters.type) 
        { 
          if (typeof type === 'string') 
          {
            this.filters.type.push(type);
          } else 
          {
            this.filters.type = type;
          }
          
        } 
        else 
        {
          this.filters.type = [];

          if (typeof type === 'string') 
          {
            this.filters.type.push(type);
          } else 
          {
            this.filters.type = type;
          }
        }
      }
      if (product_type) 
      {
        // if (this.filters.product_type) {
        //   this.filters.product_type.push(product_type);
        // } else {
        //   this.filters.product_type = [];
        //   this.filters.product_type.push(product_type);
        // }
        if (this.filters.product_type) 
        { 
          if (typeof product_type === 'string') 
          {
            this.filters.product_type.push(product_type);
          } else 
          {
            this.filters.product_type = product_type;
          }
          
        } 
        else 
        {
          this.filters.product_type = [];

          if (typeof product_type === 'string') 
          {
            this.filters.product_type.push(product_type);
          } else 
          {
            this.filters.product_type = product_type;
          }
        }
        
      }
      if (search) {
        this.filters.search = search;
      }
      this.$router.push({
        name: "pph_listing",
        query: { filters: JSON.stringify(this.filters) },
      });
    
    },
  },
};
</script>
<style scoped>
.card {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important;
}
</style>